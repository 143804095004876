<script>
export default {

}
</script>

<template>
    <div class="main-menu menu-fixed menu-dark menu-bg-default rounded menu-accordion menu-shadow">
        <div class="main-menu-content"><a class="navigation-brand d-none d-md-block d-lg-block d-xl-block" style="text-align: center" href="/dashboard"><img class="brand-logo" alt="crypto ico admin logo" src="/assets/images/logo/logo.svg" style="width: 60px !important"/></a>
          <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            <li class="active"><a href="/dashboard"><i class="icon-grid"></i><span class="menu-title" data-i18n="">Главная</span></a>
            </li>
            <li class=" nav-item"><a href="/buy"><i class="icon-layers"></i><span class="menu-title" data-i18n="">Покупка</span></a>
            </li>
            <li class=" nav-item"><a href="/wallet"><i class="icon-wallet"></i><span class="menu-title" data-i18n="">Кошелек</span></a>
            </li>
            <li class=" nav-item"><a href="/transactions"><i class="icon-shuffle"></i><span class="menu-title" data-i18n="">Транзакции</span></a>
            </li>
            <li class=" nav-item"><a href="/faq"><i class="icon-support"></i><span class="menu-title" data-i18n="">FAQ</span></a>
            </li>
          </ul>
        </div>
      </div>
</template>